import { Link } from '@/components/link'

export default function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-muted py-6 md:px-8 md:py-0">
      <div className="container flex flex-col items-center justify-between gap-4 text-sm font-medium leading-loose md:h-24 md:flex-row">
        <p>
          Copyright © {currentYear} <Link href="https://zymvol.com/" target="_blank">ZYMVOL</Link>.
        </p>
        <div>
          <ul className="flex justify-center space-x-4">
            <li>
              <Link href="/legal-notice">Legal Notice</Link>
            </li>
            <li>
              <Link href="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link href="/cookies-policy">Cookies Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
