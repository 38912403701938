import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { LinkButton } from '@/components/link'

export function CookieConsent() {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent')
    if (consent === null) {
      setShowModal(true)
    }
  }, [])

  const handleConsent = (consentType: string) => {
    localStorage.setItem('cookieConsent', consentType)
    setShowModal(false)

    if (consentType === 'accept') {
      if ((window as any).gtag) {
        // TODO: Use gtag's consent config options.
        // https://developers.google.com/tag-platform/security/guides/consent
        (window as any).gtag('config', 'G-FJ0KR5BF1L');
      }
    }
  }

  if (showModal) {
    return (
      <div className="fixed bottom-0 z-20 flex w-full flex-col items-start space-y-4 bg-primary p-4 text-primary-foreground lg:space-y-8 lg:p-8">
        <div>
          <h3 className="font-space-grotesk text-2xl font-bold">
            We value your privacy
          </h3>
          <p>We use cookies to enhance your browsing experience.</p>
          <p>
            You may deny without any impact on site functionality. For more
            details, please review our Privacy Policy.
          </p>
        </div>
        <div className="flex flex-col items-start space-y-4 sm:w-full sm:flex-row sm:justify-between sm:space-y-0">
          <div className="w-full sm:w-auto">
          <LinkButton
              className="w-full bg-primary"
              variant="outline"
              href="/cookies-policy"
            >
              Cookie Policy
            </LinkButton>
          </div>
          <div className="flex w-full flex-col space-y-4 sm:w-auto sm:flex-row sm:space-x-4 sm:space-y-0 sm:self-end">
            <Button
              className="bg-primary"
              variant="outline"
              onClick={() => handleConsent('deny')}
            >
              Deny
            </Button>
            <Button variant="secondary" onClick={() => handleConsent('accept')}>
              Accept
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
