import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { clsx, type ClassValue } from 'clsx'
import { UseFormReturn } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function normalizeUrl(url: string) {
  return '/' + url.split('/').filter(Boolean).join('/')
}

export function handleFormError(form: UseFormReturn<any>, error: any): boolean {
  if (error instanceof AxiosError) {
    if (error.response?.status == 422) {
      const fields_error = Object.entries(
        error.response.data?.error?.fields_error ?? {}
      )
      if (fields_error.length > 0) {
        // TODO: Refactor this overcomplicated mess, also in the backend...
        for (const [key, value] of fields_error) {
          if (value instanceof Array) {
            // First error message, it may contain multiple as per marshmallow
            const message = value[0]

            if (message instanceof Object) {
              // This is a workaround mainly for
              // task_parameters.substrate.0 and task_parameters.product.0
              for (const [key_, value_] of Object.entries(message)) {
                if (value_ instanceof Array) {
                  const message_ = value_[0]
                  form.setError(`${key}.${key_}.0` as any, {
                    type: 'server',
                    message: message_,
                  })
                }
              }
            } else {
              form.setError(key as any, { type: 'server', message })
            }
            // FIXME: Shall the backend return an error of a non existant field,
            // this will set an error to a non visible field.
          }
        }
        return true
      }
    }
  }
  return false
}

export function sentryCaptureException(error: any) {
  if (error instanceof AxiosError) {
    Sentry.captureException(error, {
      extra: {
        toJSON: error.toJSON(),
        ...(error.response
          ? {
              response: {
                data: JSON.stringify(error.response.data),
                status: error.response.status,
                headers: JSON.stringify(error.response.headers),
              },
            }
          : {}),
      },
    })
  }
}
