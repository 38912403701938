import { Paragraph } from '@/components/typography'

import UE from '/src/assets/Logo_200x50_01_UE.png'
import MTDFP from '/src/assets/Logo_200x50_02_MTDFP.png'
import redes from '/src/assets/Logo_200x50_03_redes.png'
import PRTR from '/src/assets/Logo_200x50_04_PRTR.png'

export default function Redes() {
  return (
    <section className="py-8 sm:py-14 lg:py-24">
      <div className="container relative mx-auto px-4 font-roboto sm:px-8">
        <div className="flex flex-col items-center space-y-5 text-balance text-zinc-500">
          <h4 className="text-center text-xl font-bold">
            Zymscan is part of the BRAINZYME project, powered by ZYMVOL.
          </h4>
          <Paragraph className="text-center text-base font-light">
            The BRAINZYME project (ref. 2021/C005/00150909) has received funding
            from the Recovery, Transformation and Resiliency Plan, in the
            framework of the European Union&apos;s NextGenerationEU, through the
            Public Business Entity Red.es, which is ascribed to the Ministry for
            Digital Transformation and Public Administration of the Government
            of Spain.
          </Paragraph>
          <div className="flex flex-wrap items-center">
            <img className="w-1/2 p-2 lg:w-1/4 lg:p-8" src={UE} alt="europe" />
            <img className="w-1/2 p-2 lg:w-1/4 lg:p-8" src={MTDFP} alt="MTDFP" />
            <img className="w-1/2 p-2 lg:w-1/4 lg:p-8" src={redes} alt="redes" />
            <img className="w-1/2 p-2 lg:w-1/4 lg:p-8" src={PRTR} alt="PRTR" />
          </div>
        </div>
      </div>
    </section>
  )
}
