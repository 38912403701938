import React from 'react'
import { cn } from '@/lib/utils'

export interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement> {}

export interface ParagraphProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}

export interface ElementProps extends React.HTMLAttributes<HTMLElement> {}

function Heading2({ className, ...props }: HeadingProps) {
  return (
    <h2
      className={cn(
        'scroll-m-20 text-5xl font-extrabold [&:not(:first-child)]:mt-6',
        className
      )}
      {...props}
    />
  )
}
Heading2.displayName = 'Heading2'

function Heading3({ className, ...props }: HeadingProps) {
  return (
    <h3
      className={cn(
        'scroll-m-20 text-4xl font-semibold [&:not(:first-child)]:mt-6',
        className
      )}
      {...props}
    />
  )
}
Heading3.displayName = 'Heading3'

function Paragraph({ className, ...props }: ParagraphProps) {
  return (
    <p
      className={cn('leading-7 [&:not(:first-child)]:mt-6', className)}
      {...props}
    />
  )
}
Paragraph.displayName = 'Paragraph'

function Strong({ className, ...props }: ElementProps) {
  return <strong className={cn('font-bold', className)} {...props} />
}
Strong.displayName = 'Strong'

export { Heading2, Heading3, Paragraph, Strong }
